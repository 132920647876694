import EvStationIcon from '@mui/icons-material/EvStation'
import GavelIcon from '@mui/icons-material/Gavel'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import CardWithIcon from 'src/components/common/CardWithIcon'
import Page from 'src/components/Page'
import {
  ROUTE_I_MEMORY,
  ROUTE_I_REMEMBER,
  ROUTE_LETTER_HAMMER,
} from 'src/resources/routes'

const Workouts = () => {
  const { t } = useTranslation(['workoutPages'])

  return (
    <Page title={t('workouts')} isPadding>
      <Typography mb="20px" textAlign="center" variant="h5" component="h2">
        {t('workouts')}
      </Typography>

      <Box mx={{ lg: '0', xs: '15px' }} display="flex" gap={2}>
        <CardWithIcon
          restProps={{ component: NavLink, to: ROUTE_I_REMEMBER }}
          cardColor="#8936b3"
          iconColor="#f0d3ff"
          textColor="#f0d3ff"
          title="iRemember"
          icon={EvStationIcon}
        />

        <CardWithIcon
          restProps={{ component: NavLink, to: ROUTE_I_MEMORY }}
          cardColor="#e1a6ff"
          iconColor="#ffffff"
          textColor="#ffffff"
          title="iMemory"
          icon={ViewModuleIcon}
        />
        <CardWithIcon
          restProps={{ component: NavLink, to: ROUTE_LETTER_HAMMER }}
          cardColor="#f0d3ff"
          iconColor="#8936b3"
          textColor="#8936b3"
          title="Letter Hammer"
          icon={GavelIcon}
        />
        {/* Next card color #ff4dff */}
      </Box>
    </Page>
  )
}

export default Workouts
