import { Card, CardActionArea, Typography } from '@mui/material'
import { MatUiIcon } from 'src/types/MatUiIcon'

interface IProp {
  onClick?: () => void
  title: string
  icon?: MatUiIcon
  iconColor?: string
  cardColor?: string
  textColor?: string
  restProps?: any
}

const CardWithIcon = ({
  onClick,
  title,
  icon: Icon,
  cardColor = 'error.light',
  textColor = 'grey.0',
  iconColor = 'error.lighter',
  restProps,
}: IProp) => {
  const handleClick = () => {
    if (onClick) onClick()
  }
  return (
    <Card
      {...restProps}
      sx={{
        width: '100%',
        height: { sm: 220, xs: 120 },
        maxWidth: { sm: 250, xs: 140 },
        backgroundColor: cardColor,
        position: 'relative',
        overflow: 'hidden',
        textDecoration: 'none',
      }}
      onClick={handleClick}
    >
      {Icon && (
        <Icon
          sx={{
            position: 'absolute',
            bottom: { sm: '-10px', xs: '5px' },
            right: { sm: '-10px', xs: '50%' },
            transform: { sm: 'translateX(0%)', xs: 'translateX(50%)' },
            color: iconColor,
            fontSize: { sm: '200px', xs: '85px' },
          }}
        />
      )}
      <CardActionArea
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: { sm: 'flex-start', xs: 'center' },
          padding: { sm: '15px', xs: '10px' },
        }}
      >
        <Typography
          variant="h5"
          color={textColor}
          fontSize={{ sm: '20px', xs: '14px' }}
        >
          {title}
        </Typography>
      </CardActionArea>
    </Card>
  )
}

export default CardWithIcon
