import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import UserStore from 'src/mobx/user'

import { getWhoAmI } from './api/requests/users'
import LoadingContainer from './components/common/LoadingContainer'
import {
  getAccessToken_LS,
  getAccessToken_SS,
} from './localStorage/access-token'
import Routes from './Routes'
import ThemeProvider from './theme'

const App = observer(() => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Authorization
    const tokenLocal = getAccessToken_LS()
    const tokenSession = getAccessToken_SS()
    if (tokenLocal || tokenSession) {
      getWhoAmI()
        .then(async (data) => {
          UserStore.setUser(data.user)
        })
        .finally(() => setLoading(false))
    } else setLoading(false)
  }, [])

  return (
    <>
      {loading ? (
        <LoadingContainer />
      ) : (
        <BrowserRouter>
          <ThemeProvider>
            <Routes />
          </ThemeProvider>
        </BrowserRouter>
      )}
    </>
  )
})

export default App
