export const ACCESS_TOKEN_STORE_KEY = 'a-token'

export const setAccessToken_LS = (val: string) => {
  localStorage.setItem(ACCESS_TOKEN_STORE_KEY, val)
}

export const getAccessToken_LS = (): string | null =>
  localStorage.getItem(ACCESS_TOKEN_STORE_KEY)

export const removeAccessToken_LS = () => {
  localStorage.removeItem(ACCESS_TOKEN_STORE_KEY)
}

// Session storage
export const setAccessToken_SS = (val: string) => {
  sessionStorage.setItem(ACCESS_TOKEN_STORE_KEY, val)
}

export const getAccessToken_SS = (): string | null =>
  sessionStorage.getItem(ACCESS_TOKEN_STORE_KEY)

export const removeAccessToken_SS = () => {
  sessionStorage.removeItem(ACCESS_TOKEN_STORE_KEY)
}
