import { styled } from '@mui/material/styles'
import { Helmet } from 'react-helmet-async'

const Container: any = styled('div')(({ theme, ispadding }: any) => ({
  ...(ispadding && {
    paddingTop: 88,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: 116,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
}))

interface IProps {
  children: any
  title: string
  meta?: any
  // Paddings for Dashboard Layout with Header
  isPadding?: boolean
}

const Page = ({ children, title = '', meta, isPadding = false }: IProps) => (
  <>
    <Helmet>
      <title>{`${title} | iVocabulary`}</title>
      {meta}
    </Helmet>

    <Container ispadding={isPadding ? 1 : 0}>{children}</Container>
  </>
)

export default Page
