export const ApiRoutes = {
  postUser: 'v1/users',
  getWhoAmI: 'v1/users/who-am-i',
  patchStartRestoration: 'v1/users/start-password-restorarion',
  patchFinishRestoration: 'v1/users/finish-password-restorarion',
  postUserReport: 'v1/users/reports',

  postLogin: 'v1/auth/login',
  getActivate: 'v1/auth/activate',
  getRefresh: 'v1/auth/refresh',
  getSignout: 'v1/auth/signout',
  postGoogleAuth: 'v1/auth/google',

  postDictionary: 'v1/dictionaries',
  putDictionary: (id: number) => `v1/dictionaries/${id}`,
  getPublicDictionary: (id: number) => `v1/dictionaries/${id}/public`,
  patchDictionaryPublicStatus: (id: number) => `v1/dictionaries/${id}/public`,
  patchDictionarySettings: (id: number) => `v1/dictionaries/${id}/settings`,
  getDictionaries: 'v1/dictionaries',
  deleteDictionary: (id: number) => `v1/dictionaries/${id}`,

  getLanguages: 'v1/languages',

  getDictionaryWords: (dictionaryId: number) =>
    `v1/words/dictionary/${dictionaryId}`,
  postWord: 'v1/words',
  deleteManyWords: 'v1/words',
  updateWord: (id: number) => `v1/words/${id}`,
  updateWordsTrainingLevel: 'v1/words/trainingLevel',
  updateWordTimesOfUsage: 'v1/words/timesOfUsage',
  updateWordsDictionary: 'v1/words/dictionary-change',
  getOxfordWord: 'v1/words/oxford',
  // Word Usage Examples
  postWordUsageExample: 'v1/word-usage-examples',
  getWordUsageExamples: (wordId: number) =>
    `v1/word-usage-examples/word/${wordId}`,
  getPublicWordUsageExamples: (wordId: number) =>
    `v1/word-usage-examples/word/${wordId}/public`,
  updateWordUsageExample: (id: number) => `v1/word-usage-examples/${id}`,
  deleteManyWordUsageExamples: (wordId: number) =>
    `v1/word-usage-examples/word/${wordId}`,
  // Word Forms
  postWordForm: 'v1/word-forms',
  getWordForms: (wordId: number) => `v1/word-forms/word/${wordId}`,
  getPublicWordForms: (wordId: number) => `v1/word-forms/word/${wordId}/public`,
  updateWordForm: (id: number) => `v1/word-forms/${id}`,
  deleteManyWordForms: (wordId: number) => `v1/word-forms/word/${wordId}`,
  // Header Slides
  getAllMySlides: 'v1/header-slides/all-my',
  headerSlides: 'v1/header-slides',
  updateSlide: (id: number) => `v1/header-slides/${id}`,
  // CV
  postCV: 'v1/pdf/cv',
}

export const todosApiRoutes = {
  post: 'v1/todos',
  postNextTask: 'v1/todos/next-repeating-todo',
  get: 'v1/todos',
  delete: 'v1/todos',
  deleteOneRepeatingTask: (id: number) => `v1/todos/${id}/one-repeating-task`,
  update: (id: number) => `v1/todos/${id}`,
  updateStatus: (id: number) => `v1/todos/${id}/status`,
  updateOrder: (id: number) => `v1/todos/${id}/order`,
}

export const todoListApiRoutes = {
  post: 'v1/todo-list-items',
  get: (todoId: number) => `v1/todo-list-items/todo/${todoId}`,
  delete: (todoId: number) => `v1/todo-list-items/todo/${todoId}`,
  deleteOneRepeatingSubtask: (todoId: number, subtaskId: number) =>
    `v1/todo-list-items/${todoId}/one-repeating-subtask/${subtaskId}`,
  update: (id: number) => `v1/todo-list-items/${id}`,
  updateOrder: (id: number) => `v1/todo-list-items/${id}/order`,
}

export const wordStuffApiRoutes = {
  translate: 'v1/word-stuff/translate',
}

export const wordPracticeSentencesApiRoutes = {
  post: 'v1/word-practice-sentences',
  get: (wordId: number) => `v1/word-practice-sentences/word/${wordId}`,
  put: (id: number) => `v1/word-practice-sentences/${id}`,
  patchOrder: (id: number) => `v1/word-practice-sentences/${id}/order`,
  delete: (wordId: number) => `v1/word-practice-sentences/word/${wordId}`,
}

export const dictionaryFocusWordsApiRoutes = {
  post: 'v1/dictionary-focus-words',
  get: 'v1/dictionary-focus-words',
  put: (id: number) => `v1/dictionary-focus-words/${id}`,
  delete: (id: number) => `v1/dictionary-focus-words/${id}`,
}
