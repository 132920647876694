import axios from 'axios'
import {
  getAccessToken_LS,
  getAccessToken_SS,
  setAccessToken_LS,
  setAccessToken_SS,
} from 'src/localStorage/access-token'
import { ROUTE_LOGIN } from 'src/resources/routes'

import { ApiRoutes } from './routes'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const checkTokenInterceptor = (request: any) => {
  const tokenLocal = getAccessToken_LS()
  const tokenSession = getAccessToken_SS()
  if (tokenLocal) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `Bearer ${tokenLocal}`
  }

  if (tokenSession) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `Bearer ${tokenSession}`
  }

  return request
}

// const checkErrors = (err) => {
//   console.log('err :', errorsHandler(err))

//   return Promise.reject(errorsHandler(err))
// }

client.interceptors.request.use(checkTokenInterceptor)

// client.interceptors.response.use(response, checkErrors)

client.interceptors.response.use(
  (config: any) => {
    return config
  },
  async (error: any) => {
    const originalRequest = error.config

    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry &&
      window.location.pathname !== ROUTE_LOGIN
    ) {
      originalRequest._isRetry = true
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/${ApiRoutes.getRefresh}`,
          {
            withCredentials: true,
          },
        )

        const token = response.data.accessToken

        const tokenLocal = getAccessToken_LS()
        if (tokenLocal) {
          setAccessToken_LS(token)
        }

        setAccessToken_SS(token)

        return client.request(originalRequest)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Axios Response Interseptor error', e)

        if (error.response && error.response.status === 401) {
          localStorage.clear()
          sessionStorage.clear()
          window.location.href = '/login'
        }
      }
    }

    throw error
  },
)

export { client }
